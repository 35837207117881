import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../layouts/layout'
import Seo from '../components/seo'
import ContentContainer from '../components/UI/ContentContainer'
import { CONTACT } from '../support'
import InlineIcon from '../components/UI/InlineIcon'

import GreenBannerBG from '../assets/images/green-banner-bg.svg'

const Hardship = ({ path }) => {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Article",
    "headline": "Financial Hardship Support",
    "datePublished": "2022-03-17",
  }
  return (
    <Layout>
      <Seo 
        title="Financial Hardship Support"
        description="If you're a SocietyOne customer and are experiencing temporary but unexpected changes to your financial situation, please contact us today."
        path={path}
        structuredData={structuredData}
      />
      <HardshipWrapper>
        <ContentContainer
          maxWidth="1020px"
          padding="80px 0 0"
          mPadding="80px 24px 0"
        >
          <h1 className="text-center">
            <span className="greenHeader">SocietyOne</span> Hardship Support
          </h1>
          <div className="hardship-hero flex-row">
            <div className="para">
              <h2>We're here for you</h2>
              <p>
                We’re taking extra measures to support our customers during
                these challenging times.
              </p>
              <p>
                We understand that on top of the uncertainty in the world as a
                result of the coronavirus pandemic, many of our customers have
                had temporary but unexpected changes.
              </p>
            </div>
            <div className="hero-img">
              <StaticImage
                src="../assets/images/hardship-hero.jpeg"
                alt="hardship-hero-image"
                placeholder='none'
              />
            </div>
          </div>
        </ContentContainer>
        <ContentContainer
          padding="120px 0 0"
          maxWidth="1020px"
          smallScreenPadding="80px 20px 0"
        >
          <div
            className="green-banner-bg"
            style={{
              backgroundImage: `url(${GreenBannerBG})`,
              backgroundSize: 'cover',
              backgroundPosition: '100% 50%',
            }}
          >
            <h2>Requesting assistance</h2>
            <p>
              If you are experiencing financial hardship, please reach out to us on 1300 254 418 or email us at <a href={`mailto:${CONTACT.EMAIL.CUSTOMER_CARE}`}>{CONTACT.EMAIL.CUSTOMER_CARE}</a>
            </p>
          </div>
        </ContentContainer>
        <ContentContainer
          padding="40px 0"
          maxWidth="1020px"
          mPadding="40px 24px"
          sPadding="40px 24px"
          xsPadding="40px 24px"
        >
          <p>
            We’re experiencing higher volumes of enquiries than usual. We
            appreciate your patience as we work to help each and every customer.
          </p>
          <p style={{ marginBottom: 8 }}>
            To talk to us about a hardship arrangement, you can reach us on:
          </p>
          <InlineIcon iconName="Phone" text="1300 254 418">
            <div style={{ marginLeft: 5 }}>Mon-Fri, 9am - 5pm AEDT</div>
          </InlineIcon>
          <p style={{ marginBottom: 8 }}>
            To request an account statement or payout figure and for all other
            enquiries, email us on:
          </p>
          <InlineIcon
            iconName="Email"
            text={CONTACT.EMAIL.CUSTOMER_SERVICE}
          />
        </ContentContainer>

        <div className="navy-banner">
          <ContentContainer
            padding="40px 0"
            maxWidth="1020px"
            mPadding="40px 20px"
            sPadding="40px 20px"
            xsPadding="40px 20px"
            background="var(--base-navy)"
          >
            <h3>Latest Update from SocietyOne</h3>
            <p>
              As an essential service, we want you to know that we’re still
              here. We’ve never had any branches, which makes things a little
              simpler, but we’re still online and we’ve enabled our teams to
              work remotely, so that they can continue to support you while also
              keeping their health and safety as a key priority.
            </p>
          </ContentContainer>
        </div>
      </HardshipWrapper>
    </Layout>
  )
}

const HardshipWrapper = styled.div`
  font-size: 18px;
  .flex-row {
    align-items: center;
    justify-content: space-between;
  }
  .para {
    max-width: 500px;
  }
  .hardship-hero {
    margin-top: 80px;
  }
  .hero-img {
    width: 100%;
    max-width: 440px;
  }
  .green-banner-bg {
    text-align: center;
    width: 100%;
    padding: 40px;
    border-radius: 20px;
    overflow: hidden;
    p {
      a {
        text-decoration: underline;
      }
    }
    button {
      margin-top: 40px;
    }
  }
  .navy-banner {
    color: var(--white);
    text-align: center;
    background-color: var(--base-navy);
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
    p {
      font-size: 16px;
    }
    .text-center {
      text-align: left;
    }
    .flex-row {
      flex-direction: column;
    }
    .hero-img {
      margin-top: 32px;
    }
  }
`

export default Hardship
